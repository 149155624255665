
import { Options, Vue } from 'vue-class-component';
import { LoginServices } from '@/services/LoginServices';
import { AfterLoginMandatoryDocumentClient, AuthClient } from '@/services/Services';
import SocialLogin from '../components/socialLogin.vue';
import * as OM from '@/model';
import { StorageServices } from '@/services/StorageServices';
import MandatoryDocuments from '@/components/mandatoryDocuments.vue'

@Options({
    components: {
        SocialLogin,
        MandatoryDocuments
    }
})
export default class Login extends Vue {
    
    documents: OM.MandatoryDocumentAppVM[] = [];
    vm: OM.LanguageCredentialWithBirthDate = new OM.LanguageCredentialWithBirthDate();
    loading: boolean = false;

    message: string = "";
    error: string = "";

    created(){
        AfterLoginMandatoryDocumentClient.getRegistrationMustSign()
        .then( x => {
            this.documents = x;
        })
    }

    doLogin(){
        if(!this.vm.username || !this.vm.password)
            return;

        this.error = "";
        this.loading = true;
        AuthClient.login(this.vm, false)
        .then(x => {
            StorageServices.setUserIdentifier(x.identifier);

            if(!x.token){
                this.$router.push("confirmedCode");
                return;
            }

            var vm = new OM.LoginResponse();
            vm.token = x.token;
            vm.userIdentifier = x.identifier;
            LoginServices.loginCallback(vm, this);
        })
        .catch(err => {
            this.error = err.message;

            setTimeout(() => {
                this.error = "";
            }, 3000);
        })
        .finally(() => this.loading = false)
    }

    doError(){
        
    }

}
